<template>
  <v-container
    ><SiteHeaderComponent :header="address.name" />
    <div class="text-center grey--text text--darken-2 mb-12">
      <h3>
        <router-link
          :to="{
            name: 'statisticsDistributorArticleTypes',
            params: { lfdnr: lfdnr }
          }"
          >{{ result.typeName }}
        </router-link>
        > Artikelkategorien
      </h3>
    </div>
    <div class="d-flex justify-end">
      <v-btn
        class="rounded-pill"
        color="primary"
        :to="{
          name: 'statisticsArticleSearch',
          params: { lfdnr: lfdnr },
          query: this.$route.query
        }"
      >
        <v-icon class="mr-2"> mdi-magnify </v-icon>
        Artikelsuche
      </v-btn>
    </div>
    <div>
      <TimeFilter />
    </div>

    <v-card class="mx-auto mb-12">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suche"
          outlined
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="result.categories"
        :items-per-page="30"
        :search="search"
        item-key="code"
        class="elevation-1 datatable-row-pointer"
        :loading="loading"
        sort-by="description"
        :sort-desc="sortDesc"
        mobile-breakpoint="800"
        @click:row="openArticleCategory"
      >
        <template v-slot:no-data>
          Keine Artikelkategorien in diesem Zeitraum vorhanden.
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

// Services
import addressService from '@/services/crm/addressService.js'
import distributorService from '@/services/statistics/distributorService.js'

// Components
import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue'
import TimeFilter from '@/components/statistics/TimeFilter.vue'

export default {
  components: {
    SiteHeaderComponent,
    TimeFilter
  },
  data: () => ({
    address: {
      name: '',
      street: '',
      postcode: '',
      city: '',
      country: '',
      tel: '',
      email: '',
      addressTypes: []
    },
    result: {},
    loading: true,
    search: '',
    sortDesc: false,
    editorContent: '',
    headers: [
      {
        text: 'Bezeichnung',
        align: 'start',
        value: 'description'
      },
      {
        text: 'Anfangsbestand (€)',
        value: 'openingValue',
        align: 'end'
      },
      {
        text: 'Einkäufe (€)',
        value: 'purchasesValue',
        align: 'end'
      },
      {
        text: 'Verkäufe (€)',
        value: 'salesValue',
        align: 'end'
      },
      {
        text: 'Inventur (€)',
        value: 'inventoryValue',
        align: 'end'
      },
      {
        text: 'Materialverbrauch (€)',
        value: 'materialsInputValue',
        align: 'end'
      },
      {
        text: 'Endbestand (€)',
        value: 'finalValue',
        align: 'end'
      }
    ]
  }),
  props: {
    lfdnr: {
      type: Number,
      required: true
    },
    articleType: {
      type: String,
      required: true
    }
  },
  methods: {
    init() {
      addressService.getAddress(this.lfdnr).then(response => {
        this.address = response.data
      })

      this.loadArticleCategoriesStatistics()
    },
    loadArticleCategoriesStatistics() {
      this.loading = true
      distributorService
        .getArticleCategoriesStatistics(
          this.lfdnr,
          this.articleType,
          this.filterFromDate,
          this.filterToDate
        )
        .then(response => {
          this.result = response.data
          this.loading = false
        })
    },
    openArticleCategory(item) {
      // statisticsDistributorArticleCategories
      this.$router.push({
        name: 'statisticsDistributorArticles',
        query: {
          from: this.filterFromDate,
          to: this.filterToDate
        },
        params: {
          lfdnr: this.lfdnr,
          articleType: this.articleType,
          category: item.nr
        }
      })
    }
  },
  computed: {
    ...mapState({
      filterFromDate: state => state.statisticsTimeFilter.fromDate,
      filterToDate: state => state.statisticsTimeFilter.toDate
    })
  },
  mounted() {
    this.init()
  },
  watch: {
    filterFromDate: function() {
      this.loadArticleCategoriesStatistics()
    },
    filterToDate: function() {
      this.loadArticleCategoriesStatistics()
    }
  }
}
</script>

<style scoped>
.datatable-row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
