<template>
  <v-container>
    <div class="mx-auto my-12 v-card v-sheet theme--light">
      <v-tabs grow v-model="currentTab" show-arrows>
        <v-tab href="#IncomingOrders">Auftragseingänge</v-tab>
        <v-tab href="#MonthlyComparisons">Monatsansicht</v-tab>
        <v-tab href="#OrderValuePerCustomer">Auftragswert / Kunde</v-tab>
        <v-tab href="#NumberOfOrdersAndCustomers"
          >Auftragsanzahl, Kundenanzahl</v-tab
        >
        <v-tab href="#Forecast">Forecast</v-tab>
      </v-tabs>
      <v-tabs-items v-model="currentTab">
        <v-tab-item value="IncomingOrders">
          <IncomingOrders />
        </v-tab-item>
        <v-tab-item value="MonthlyComparisons">
          <MonthlyComparisons />
        </v-tab-item>
        <v-tab-item value="OrderValuePerCustomer">
          <OrderValuePerCustomer />
        </v-tab-item>
        <v-tab-item value="NumberOfOrdersAndCustomers">
          <NumberOfOrdersAndCustomers />
        </v-tab-item>
        <v-tab-item value="Forecast">
          <Forecast />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-container>
</template>

<script>
import IncomingOrders from '@/components/statistics/offer/IncomingOrders.vue'
import MonthlyComparisons from '@/components/statistics/offer/MonthlyComparisons.vue'
import OrderValuePerCustomer from '@/components/statistics/offer/OrderValuePerCustomer.vue'
import NumberOfOrdersAndCustomers from '@/components/statistics/offer/NumberOfOrdersAndCustomers.vue'
import Forecast from '@/components/statistics/offer/Forecast.vue'

export default {
  components: {
    IncomingOrders,
    MonthlyComparisons,
    OrderValuePerCustomer,
    NumberOfOrdersAndCustomers,
    Forecast
  },
  data: () => ({
    currentTab: ''
  }),
  watch: {
    currentTab() {
      const tab = this.currentTab
      if (this.$route.query.tab != tab)
        this.$router.replace({ query: { ...this.$route.query, tab } })
      this.$store.dispatch('setOfferCurrentTab', tab)
      this.$store.dispatch('setOfferSelectedYear', -1)
      this.$store.dispatch('setOfferMonthly', false)
      this.$store.dispatch('setLoading', false)
    }
  },
  mounted() {
    this.currentTab = this.$route.query.tab
    this.init()
  }
}
</script>
