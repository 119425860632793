<template>
  <v-container>
    <div class="mx-auto my-12 v-card v-sheet theme--light">
      <v-tabs grow v-model="currentTab">
        <v-tab href="#total">Gesamt</v-tab>
        <v-tab href="#stores">Filialen</v-tab>
        <v-tab href="#addressTypes">Adressarten</v-tab>
        <v-tab href="#customersGroups">Kundengruppen</v-tab>
      </v-tabs>
      <v-tabs-items v-model="currentTab">
        <v-tab-item value="total">
          <totalDevelopment />
        </v-tab-item>
        <v-tab-item value="stores">
          <storeDevelopment />
        </v-tab-item>
        <v-tab-item value="addressTypes">
          <addressTypes />
        </v-tab-item>
        <v-tab-item value="customersGroups">
          <customersGroups />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-container>
</template>

<script>
import totalDevelopment from '@/components/statistics/customerDevelopment/totalDevelopment.vue'
import storeDevelopment from '@/components/statistics/customerDevelopment/storeDevelopment.vue'
import customersGroups from '@/components/statistics/customerDevelopment/customersGroups.vue'
import addressTypes from '@/components/statistics/customerDevelopment/addressTypes.vue'

export default {
  components: {
    totalDevelopment,
    storeDevelopment,
    customersGroups,
    addressTypes
  },

  data: () => ({
    currentTab: ''
  }),
  watch: {
    currentTab() {
      const tab = this.currentTab
      if (this.$route.query.tab != tab)
        this.$router.replace({ query: { ...this.$route.query, tab } })
    }
  },
  mounted() {
    this.currentTab = this.$route.query.tab
  }
}
</script>
