<template>
  <v-container>
    <template>
      <v-card-title>
        Aufwandskonto auswählen
      </v-card-title>
      <v-card-text>
        <v-text-field
          class="shrink mb-3"
          v-model="aufwandsKontenSearch"
          append-icon="mdi-magnify"
          label="Suche"
          outlined
          hide-details
        ></v-text-field>
        <v-data-table
          v-model="aufwandsKontenSelected"
          :headers="aufwandsKontenHeaders"
          :items="aufwandsKontenArray"
          :single-select="singleSelect"
          :search="aufwandsKontenSearch"
          :items-per-page="10"
          item-key="group"
          show-select
          class="elevation-1"
          @input="showArticlesInAufwandsKonten"
        >
        </v-data-table
      ></v-card-text>
    </template>
    <template v-if="this.aufwandsKontenSelected.length != 0">
      <v-card-title>
        <v-text-field
          v-model="articlesInAufwandsKontenSearch"
          append-icon="mdi-magnify"
          label="Suche"
          single-line
          outlined
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="articlesInAufwandsKontenHeaders"
        :items="articlesInAufwandsKontenArray"
        :search="articlesInAufwandsKontenSearch"
        :items-per-page="10"
        item-key="articlename"
        class="elevation-1"
      >
      </v-data-table>
    </template>
    <!------------------------------------------------------>
    <div v-if="this.aufwandsKontenSelected.length != 0">
      <div class="header">
        <h2>
          Entwicklung der Aufwandskonten
          <span class="subtitle-1"
            >{{ this.highestYearInFiveShownYearsOfSatisticGroups - 4 }}-{{
              this.highestYearInFiveShownYearsOfSatisticGroups
            }}</span
          >
        </h2>
        <v-col class="d-flex justify-end">
          <v-btn icon color="black" @click="increaseFiveYears">
            <v-icon>mdi-arrow-left-thick</v-icon>
          </v-btn>

          <v-btn icon color="black" @click="decreaseFiveYears">
            <v-icon>mdi-arrow-right-thick</v-icon>
          </v-btn>
        </v-col>
      </div>
      <apexchart
        ref="fiveYearChartsOfAufwandsKonten"
        type="line"
        height="350"
        :options="fiveYearChartOptionsOfAufwandsKonten"
        :series="fiveYearChartsOfAufwandsKontenSeries"
      ></apexchart>
    </div>
    <!------------------------------------------------------>
    <div v-if="selectedYear != 0">
      <div class="header">
        <h2>
          Monatsansicht
          <span class="subtitle-1">{{ this.selectedYear }}</span>
        </h2>
        <v-col class="d-flex justify-end">
          <v-btn icon color="black" @click="increaseOneYear">
            <v-icon>mdi-arrow-left-thick</v-icon>
          </v-btn>

          <v-btn icon color="black" @click="decreaseOneYear">
            <v-icon>mdi-arrow-right-thick</v-icon>
          </v-btn>
        </v-col>
      </div>
      <apexchart
        ref="oneYearChartsOfAufwandsKonten"
        type="line"
        height="350"
        :options="oneYearChartOptionsOfAufwandsKonten"
        :series="oneYearChartsOfAufwandsKontenSeries"
      ></apexchart>
    </div>
    <!------------------------------------------------------>
  </v-container>
</template>

<script>
import aufwandsKontenService from '@/services/statistics/aufwandskontenService.js'
import FormatService from '@/services/formatters/currencyFormatService.js'
import { monthNames } from '@/services/statistics/chartService.js'

const REVENUE_TYPES = ['net', 'db', 'netTotal', 'dbTotal']

export default {
  data: function() {
    return {
      aufwandsKontenSearch: '',
      articlesInAufwandsKontenSearch: '',
      aufwandsKontenSelected: [],
      statisticGroupsObjectArray: [],
      aufwandsKontenArray: [],
      singleSelect: true,
      highestYearInFiveShownYearsOfSatisticGroups: new Date().getFullYear(),
      selectedYear: 0,
      aufwandsKontenHeaders: [
        {
          text: 'Aufwandskonto',
          value: 'group',
          align: 'start',
          sortable: true
        }
      ],
      articlesInAufwandsKontenHeaders: [
        {
          text: 'Artikel',
          value: 'articlename',
          align: 'start',
          sortable: true
        },
        {
          text: 'Artikelnummer',
          value: 'artNr',
          align: 'start',
          sortable: true
        }
      ],
      articlesInAufwandsKontenArray: [],
      articlesInStatisticGroupObjectArray: [],
      ///////////////////////////
      // Five Year Chart //
      fiveYearChartsOfAufwandsKontenSeries: [],
      fiveYearChartOptionsOfAufwandsKonten: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: (event, chartContext, config) => {
              this.showMonth(
                this.highestYearInFiveShownYearsOfSatisticGroups -
                  4 +
                  config.dataPointIndex
              )
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: [
            new Date().getFullYear() - 4,
            new Date().getFullYear() - 3,
            new Date().getFullYear() - 2,
            new Date().getFullYear() - 1,
            new Date().getFullYear()
          ]
        },
        yaxis: [
          {
            /*
            data from api: 4349.4685
            data after format: 4.000 €
          */
            decimalsInFloat: 0,
            labels: {
              formatter: function(value) {
                return (
                  ('' + value).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, function(
                    $1
                  ) {
                    return $1 + '.'
                  }) + ' €'
                )
              }
            }
          }
        ],
        tooltip: {
          shared: true,
          y: {
            formatter: function(
              value,
              { series, seriesIndex, dataPointIndex }
            ) {
              if (REVENUE_TYPES[seriesIndex] == 'db') {
                let pvalue = parseFloat(
                  (series[seriesIndex][dataPointIndex] /
                    series[seriesIndex - 1][dataPointIndex] || 0) * 100
                )
                return (
                  FormatService.formatEuro(value) +
                  ' (' +
                  (isFinite(pvalue) ? pvalue : 0).toFixed(2) +
                  '%)'
                )
              } else if (REVENUE_TYPES[seriesIndex] == 'dbTotal') {
                return (
                  FormatService.formatEuro(value) +
                  ' (' +
                  (
                    (series[seriesIndex][dataPointIndex] /
                      series[seriesIndex - 1][dataPointIndex] || 0) * 100
                  ).toFixed(2) +
                  '%)'
                )
              } else {
                return FormatService.formatEuro(value)
              }
            }
          }
        }
      },
      ////////////////////
      // One Year Chart //
      oneYearChartsOfAufwandsKontenSeries: [],
      oneYearChartOptionsOfAufwandsKonten: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: monthNames
        },
        yaxis: [
          {
            /*
            data from api: 4349.4685
            data after format: 4.000 €
          */
            decimalsInFloat: 0,
            labels: {
              formatter: function(value) {
                return (
                  ('' + value).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, function(
                    $1
                  ) {
                    return $1 + '.'
                  }) + ' €'
                )
              }
            }
          }
        ],
        tooltip: {
          shared: true,
          y: {
            formatter: function(
              value,
              { series, seriesIndex, dataPointIndex }
            ) {
              if (REVENUE_TYPES[seriesIndex] == 'db') {
                let pvalue = parseFloat(
                  (series[seriesIndex][dataPointIndex] /
                    series[seriesIndex - 1][dataPointIndex] || 0) * 100
                )
                return (
                  FormatService.formatEuro(value) +
                  ' (' +
                  (isFinite(pvalue) ? pvalue : 0).toFixed(2) +
                  '%)'
                )
              } else if (REVENUE_TYPES[seriesIndex] == 'dbTotal') {
                return (
                  FormatService.formatEuro(value) +
                  ' (' +
                  (
                    (series[seriesIndex][dataPointIndex] /
                      series[seriesIndex - 1][dataPointIndex] || 0) * 100
                  ).toFixed(2) +
                  '%)'
                )
              } else {
                return FormatService.formatEuro(value)
              }
            }
          }
        }
      }
      //////////////////////////
    }
  },
  methods: {
    shwoStatistikGroups() {
      aufwandsKontenService.getListOfAllAufwandsKonten().then(response => {
        this.statisticGroupsObjectArray = response.data
        this.statisticGroupsObjectArray.forEach(element => {
          this.aufwandsKontenArray.push({
            group: element.name,
            code: element.id
          })
        })
      })
    },
    showArticlesInAufwandsKonten() {
      this.selectedYear = 0

      console.log(this.aufwandsKontenSelected[0].code)
      aufwandsKontenService
        .getListOfArticlesInAufwandskonten(this.aufwandsKontenSelected[0].code)
        .then(response => {
          this.articlesInStatisticGroupObjectArray = response.data
          this.articlesInAufwandsKontenArray = []
          this.articlesInStatisticGroupObjectArray.forEach(article => {
            this.articlesInAufwandsKontenArray.push({
              articlename: article.artBezeichung,
              artNr: article.artNr
            })
          })
        })
      this.showFiveYearsOfArticleGroups()
    },
    showFiveYearsOfArticleGroups() {
      let dbTotal = []
      let posNettoTotal = []
      let dbYear = []
      let revenueYear = []
      let responseDataArray = []
      console.log(
        this.aufwandsKontenSelected[0].code,
        this.highestYearInFiveShownYearsOfSatisticGroups - 4
      )
      aufwandsKontenService
        .getFiveYearDataOfAufwandskonten(
          this.aufwandsKontenSelected[0].code,
          this.highestYearInFiveShownYearsOfSatisticGroups - 4
        )
        .then(response => {
          responseDataArray = response.data

          responseDataArray.forEach(response => {
            dbTotal.push(response.dbTotal)
            posNettoTotal.push(response.posNettoTotal)

            revenueYear.push(response.revenueYear)
            dbYear.push(response.dbYear)
          })
          this.$refs.fiveYearChartsOfAufwandsKonten.updateOptions({
            xaxis: {
              categories: [
                this.highestYearInFiveShownYearsOfSatisticGroups - 4,
                this.highestYearInFiveShownYearsOfSatisticGroups - 3,
                this.highestYearInFiveShownYearsOfSatisticGroups - 2,
                this.highestYearInFiveShownYearsOfSatisticGroups - 1,
                this.highestYearInFiveShownYearsOfSatisticGroups
              ]
            }
          })
          this.$refs.fiveYearChartsOfAufwandsKonten.updateSeries([
            {
              name: 'Umsatz',
              data: revenueYear,
              type: 'line'
            },
            {
              name: 'Deckungsbeitrag',
              data: dbYear,
              type: 'line'
            },
            {
              name: 'Umsatz gesamt',
              data: posNettoTotal,
              type: 'bar'
            },
            {
              name: 'Deckungsbeitrag gesamt',
              data: dbTotal,
              type: 'bar'
            }
          ])
        })
    },
    showMonth(year) {
      console.log(year)
      this.selectedYear = year

      let dbTotal = []
      let posNettoTotal = []
      let dbYear = []
      let revenueMonth = []
      let responseDataArray = []

      aufwandsKontenService
        .getOneYearDataOfAufwandskonten(
          this.aufwandsKontenSelected[0].code,
          year
        )
        .then(response => {
          responseDataArray = response.data

          responseDataArray.forEach(response => {
            dbTotal.push(response.dbTotal)
            posNettoTotal.push(response.posNettoTotal)

            revenueMonth.push(response.revenueMonth)
            dbYear.push(response.dbMonth)
          })

          this.$refs.oneYearChartsOfAufwandsKonten.updateSeries([
            {
              name: 'Umsatz',
              data: revenueMonth,
              type: 'line'
            },
            {
              name: 'Deckungsbeitrag',
              data: dbYear,
              type: 'line'
            }
          ])
        })
    },
    increaseOneYear() {
      this.selectedYear--
      this.showMonth(this.selectedYear)
    },
    decreaseOneYear() {
      if (this.selectedYear < new Date().getFullYear()) {
        this.selectedYear++
        this.showMonth(this.selectedYear)
      }
    },
    increaseFiveYears() {
      this.highestYearInFiveShownYearsOfSatisticGroups--
      this.showFiveYearsOfArticleGroups()
    },
    decreaseFiveYears() {
      if (
        this.highestYearInFiveShownYearsOfSatisticGroups <
        new Date().getFullYear()
      ) {
        this.highestYearInFiveShownYearsOfSatisticGroups++
        this.showFiveYearsOfArticleGroups()
      }
    }
  },
  beforeMount() {
    this.shwoStatistikGroups()
  }
}
</script>

<style></style>
