<template>
  <div class="mx-4 my-4">
    <div class="d-flex mx-4">
      <span class="text-h6 font-weight-light d-inline-flex">
        <v-select
          class="me-3 fit"
          hint="Anfangsjahr"
          persistent-hint
          :items="getOfferYearItems"
          v-model="getOfferYearStart"
          @change="selected => yearStartSelectionChange(selected)"
        ></v-select>
        <span class="mt-4 text-center">-</span>
        <v-select
          class="mx-3 fit"
          hint="Endjahr"
          persistent-hint
          :items="getOfferYearItems"
          v-model="getOfferYearEnd"
          @change="selected => yearEndSelectionChange(selected)"
        ></v-select>
        <v-checkbox
          v-model="checkbox"
          label="aktuellen Monat inkludieren"
        ></v-checkbox>
      </span>
    </div>

    <div class="d-flex justify-end">
      <v-btn icon color="black" @click="yearBack">
        <v-icon>mdi-arrow-left-thick</v-icon>
      </v-btn>

      <v-btn icon color="black" @click="yearForward">
        <v-icon>mdi-arrow-right-thick</v-icon>
      </v-btn>
    </div>

    <apexchart
      ref="yearChart"
      type="line"
      height="350"
      :options="yearlyChartOptions"
      :series="yearOfferObjectArray"
    ></apexchart>
  </div>
</template>

<script>
import OfferService from '@/services/statistics/offerService.js'
import { mapGetters } from 'vuex'

const MixinMethodObject = {
  methods: {
    yearStartSelectionChange(selected) {
      if (selected < this.getOfferYearEnd) {
        this.$store.dispatch('setOfferYearStart', selected)
      } else {
        this.$store.dispatch('setOfferYearStart', this.getOfferYearEnd)
      }
    },
    yearEndSelectionChange(selected) {
      if (selected > this.getOfferYearStart) {
        this.$store.dispatch('setOfferYearEnd', selected)
      } else {
        this.$store.dispatch('setOfferYearEnd', this.getOfferYearStart)
      }
    },
    yearBack() {
      let yearStart = this.getOfferYearStart
      if (yearStart != this.getOfferMinYear) {
        if (this.getOfferSelectedYear > this.getOfferYearEnd - 1) {
          this.$store.dispatch(
            'setOfferSelectedYear',
            this.getOfferSelectedYear - 1
          )
        }
        this.$store.dispatch('setOfferYearStart', yearStart - 1)
        this.$store.dispatch('setOfferYearEnd', this.getOfferYearEnd - 1)
      }
    },
    yearForward() {
      let yearEnd = this.getOfferYearEnd
      if (yearEnd != this.getOfferCurrentYear) {
        if (this.getOfferSelectedYear < this.getOfferYearStart + 1) {
          this.$store.dispatch(
            'setOfferSelectedYear',
            this.getOfferSelectedYear + 1
          )
        }
        this.$store.dispatch('setOfferYearStart', this.getOfferYearStart + 1)
        this.$store.dispatch('setOfferYearEnd', yearEnd + 1)
      }
    }
  }
}

export default {
  mixins: [MixinMethodObject],
  data: () => ({
    checkbox: true,
    yearOfferObjectArray: [],
    yearlyChartOptions: {
      title: {
        align: 'left',
        style: {
          fontSize: '20px'
        }
      },
      chart: {
        zoom: {
          enabled: false
        }
      },
      markers: {
        enabled: true,
        size: 5
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        title: {
          text: 'Jahr'
        }
      },
      yaxis: [
        {
          title: {
            text: 'Anzahl'
          }
        }
      ]
    }
  }),
  methods: {
    init() {
      this.$store.dispatch('setOfferMonthly', false)

      OfferService.getMinimumYearForOffer().then(res => {
        let currentYear = this.getOfferCurrentYear

        this.$store.dispatch('setOfferYearStart', currentYear - 2) // setting yearStart to year to current Year -2 (for example: 2022 (currentYear) - 2 = 2020 (yearStart))
        this.$store.dispatch('setOfferYearEnd', currentYear) // setting yearEnd to current Year
        this.$store.dispatch('setOfferMinYear', res.data)

        // filling items: Array with year-numbers (minYear - currentYear) and saving it to YearItems
        let items = []
        for (let i = this.getOfferMinYear; i <= currentYear; i++) {
          items.push(i)
        }
        this.$store.dispatch('setOfferYearItems', items)
        this.updateOrdersOfYearSpan()
      })
    },

    updateOrdersOfYearSpan() {
      let yearYearObjectArray = (this.yearYearObjectArray = [])

      OfferService.getOffersForYearSpan(
        '',
        this.getOfferYearStart,
        this.getOfferYearEnd,
        this.checkbox
      ).then(res => {
        for (
          let year = this.getOfferYearStart;
          year <= this.getOfferYearEnd;
          year++
        ) {
          yearYearObjectArray.push(year)
        }
        let offerArray = []
        let flag = true
        res.data.forEach(offerObject => {
          if (Object.values(offerObject).every(x => x <= 0)) {
            flag = false
          }
          offerArray.push(offerObject.orders)
        })
        if (flag == true) {
          this.$refs.yearChart.updateOptions({
            yaxis: {
              min: 0
            }
          })
        }
        this.$refs.yearChart.updateSeries([
          {
            name: 'Aufträge',
            data: offerArray
          }
        ])
        this.$refs.yearChart.updateOptions({
          title: {
            text:
              'Jahresansicht ' +
              this.getOfferYearStart +
              ' - ' +
              this.getOfferYearEnd
          },
          xaxis: {
            categories: yearYearObjectArray
          }
        })
      })
    }
  },
  computed: {
    ...mapGetters([
      'getOfferMinYear',
      'getOfferYearStart',
      'getOfferYearEnd',
      'getOfferYearItems',
      'getOfferSelectedYear',
      'getOfferCurrentYear'
    ]),
    getYearStartAndGetYearEnd() {
      return `${this.getOfferYearStart}|${this.getOfferYearEnd}`
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    getYearStartAndGetYearEnd() {
      this.updateOrdersOfYearSpan()
    },
    checkbox() {
      this.updateOrdersOfYearSpan()
    }
  }
}
</script>

<style scoped>
.v-select__selections {
  font-weight: bold;
  padding-right: 50px;
}
.v-select.fit {
  width: 100px;
}
.v-select.fit .v-select__selection--comma {
  text-overflow: unset;
}
</style>
