<template>
  <div class="mx-4 my-4">
    <div class="d-flex mx-4">
      <span class="text-h6 font-weight-light d-inline-flex">
        <v-select
          class="me-3 fit"
          hint="Anfangsjahr"
          persistent-hint
          :items="getOfferYearItems"
          v-model="getOfferYearStart"
          @change="selected => yearStartSelectionChange(selected)"
        ></v-select>
        <span class="mt-4 text-center">-</span>
        <v-select
          class="mx-3 fit"
          hint="Endjahr"
          persistent-hint
          :items="getOfferYearItems"
          v-model="getOfferYearEnd"
          @change="selected => yearEndSelectionChange(selected)"
        ></v-select>
      </span>
    </div>

    <div class="d-flex justify-end">
      <v-btn icon color="black" @click="yearBack">
        <v-icon>mdi-arrow-left-thick</v-icon>
      </v-btn>

      <v-btn icon color="black" @click="yearForward">
        <v-icon>mdi-arrow-right-thick</v-icon>
      </v-btn>
    </div>

    <apexchart
      ref="yearChart"
      type="line"
      height="350"
      :options="yearlyChartOptions"
      :series="yearOfferObjectArray"
    ></apexchart>

    <MonthChart />
  </div>
</template>

<script>
import OfferService from '@/services/statistics/offerService.js'
import MonthChart from '@/components/statistics/Revenue/MonthChart.vue'
import { mapGetters } from 'vuex'
import FormatService from '@/services/formatters/currencyFormatService.js'
import MixinMethodObject from '@/components/statistics/offer/IncomingOrders.vue'

export default {
  components: {
    MonthChart
  },
  mixins: [MixinMethodObject],
  data: () => ({
    yearOfferObjectArray: [],
    yearlyChartOptions: {
      title: {
        align: 'left',
        style: {
          fontSize: '20px'
        }
      },
      chart: {
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: true
      },
      markers: {
        enabled: true,
        size: 5
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        title: {
          text: 'Jahr'
        }
      },
      yaxis: [
        {
          title: {
            text: 'Anzahl'
          }
        }
      ],
      states: {
        active: {
          filter: {
            type: 'none' /* none, lighten, darken */
          }
        }
      }
    }
  }),
  methods: {
    updateOrdersOfYearSpan() {
      let yearYearObjectArray = (this.yearYearObjectArray = [])

      OfferService.getOffersForYearSpan(
        '/offerPerCustomer',
        this.getOfferYearStart,
        this.getOfferYearEnd
      ).then(res => {
        for (
          let year = this.getOfferYearStart;
          year <= this.getOfferYearEnd;
          year++
        ) {
          yearYearObjectArray.push(year)
        }
        let newCustomerArray = []
        let totalCustomerArray = []
        let newOffers = []
        let totalOffers = []
        let offerPerCustomer = []
        let roundedOfferPerCustomer = []

        let flag = true
        res.data.forEach(offerObject => {
          if (Object.values(offerObject).every(x => x <= 0)) {
            flag = false
          }
          newCustomerArray.push(offerObject.anzahlKunden)
          totalCustomerArray.push(offerObject.gesamtAnzahlKunden)
          newOffers.push(offerObject.anzahlAuftraege)
          totalOffers.push(offerObject.gesamtAnzahlAuftraege)
          offerPerCustomer.push(offerObject.auftraegePerKunde)
        })

        if (flag == true) {
          this.$refs.yearChart.updateOptions({
            yaxis: {
              min: 0,
              decimalsInFloat: 0,
              labels: {
                formatter: value => FormatService.formatEuro(value)
              }
            }
          })
        }

        offerPerCustomer.forEach(element => {
          roundedOfferPerCustomer.push(Math.round(element * 100) / 100)
        })

        this.$refs.yearChart.updateSeries([
          {
            name: 'Neue Kunden',
            data: newCustomerArray,
            type: 'line'
          },
          {
            name: 'Gesamt Kunden',
            data: totalCustomerArray,
            type: 'bar'
          },
          {
            name: 'Neue Aufträge',
            data: newOffers,
            type: 'line'
          },
          {
            name: 'Gesamt Aufträge',
            data: totalOffers,
            type: 'bar'
          },
          {
            name: '⌀ Aufträge Per Kunde',
            data: roundedOfferPerCustomer,
            type: 'line'
          }
        ])
        this.$refs.yearChart.updateOptions({
          title: {
            text:
              'Jahresansicht ' +
              this.getOfferYearStart +
              ' - ' +
              this.getOfferYearEnd
          },
          xaxis: {
            categories: yearYearObjectArray
          }
        })
      })
    }
  },
  computed: {
    ...mapGetters([
      'getOfferYearStart',
      'getOfferYearEnd',
      'getOfferYearItems'
    ]),
    getYearStartAndGetYearEnd() {
      return `${this.getOfferYearStart}|${this.getOfferYearEnd}`
    }
  },
  watch: {
    getYearStartAndGetYearEnd() {
      this.updateOrdersOfYearSpan()
    }
  }
}
</script>

<style></style>
