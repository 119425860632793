<template>
  <div>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Suche"
        outlined
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :items-per-page="10"
      item-key="articlename"
      class="elevation-1"
    >
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    headers: { type: Array, required: true },
    items: { type: Array, required: true }
  },
  data: function() {
    return {
      search: ''
    }
  }
}
</script>

<style></style>
