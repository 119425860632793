<template>
  <div>
    <v-card-title> {{ this.tabName }} auswählen </v-card-title>
    <v-text-field
      class="shrink mb-3"
      v-model="search"
      append-icon="mdi-magnify"
      label="Suche"
      outlined
      hide-details
    ></v-text-field>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="items"
      :single-select="singleSelect"
      :search="search"
      :items-per-page="10"
      :item-key="dataTableKey"
      show-select
      class="elevation-1"
      @input="$emit('show', selected)"
    >
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    tabName: { type: String, required: true },
    headers: { type: Array, required: true },
    items: { type: Array, required: true },
    dataTableKey: { type: String, required: true }
  },
  data: function() {
    return {
      search: '',
      singleSelect: true,
      selected: []
    }
  }
}
</script>

<style></style>
