<template>
  <v-container>
    <div class="mx-5">
      <h2 class=" mb-3">Adressart Auswählen</h2>
      <v-select
        class="mb-5 mt-3"
        :items="addressTypesItems"
        item-text="name"
        item-value="id"
        label="Adressart"
        v-model="selectedAddressType"
        @input="showFiveYears"
      ></v-select>
      <div v-if="selectedAddressType != ''">
        <fiveYearGraph
          :highestYearInFiveShownYears="highestYearInFiveShownYears"
          :fiveYearDataArray="fiveYearDataArray"
          :headLineText="headLineText"
          :entireCustomerInFiveYears="entireCustomerInFiveYears"
          :activeCustomer="activeCustomer"
          :inactiveCustomer="inactiveCustomer"
          @increaseOneYearInFiveYearChart="increaseOneYearInFiveYearChart"
          @decreaseOneYearInFiveYearChart="decreaseOneYearInFiveYearChart"
          @showMonth="showMonth"
        />
      </div>
      <div
        v-else
        class="text-center mx-auto my-8 py-3 text--disabled"
        style="width:30%"
      >
        Wählen Sie eine Adressart
      </div>

      <div v-if="currentYear != 0">
        <monthGraph
          :currentYear="currentYear"
          :monthlyData="newCustomerPerMonth"
          :entireCustomerData="entireCustomerData"
          :activeCustomer="activeCustomerOfMonth"
          :inactiveCustomer="inactiveCustomerOfMonth"
          @increaseOneYear="increaseOneYear"
          @decreaseOneYear="decreaseOneYear"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import customerDevelopmentService from '@/services/statistics/customerDevelopmentService.js'
import monthGraph from '@/components/statistics/customerDevelopment/monthGraph.vue'
import fiveYearGraph from '@/components/statistics/customerDevelopment/fiveYearGraph.vue'
//import customerDatatable from '@/components/statistics/customerDevelopment/customerDatatable.vue'

export default {
  components: {
    monthGraph,
    fiveYearGraph
    //customerDatatable
  },
  data: function() {
    return {
      currentYear: 0,
      highestYearInFiveShownYears: new Date().getFullYear(),
      selectedAddressType: '',
      addressTypesItems: [],
      entireCustomerData: [],
      activeCustomerOfMonth: [],
      inactiveCustomerOfMonth: [],
      newCustomerPerMonth: [],
      entireCustomerInMonth: [],
      activeCustomer: [],
      inactiveCustomer: [],

      fiveYearDataArray: [],
      headLineText: '',
      newCustomerDatatableItems: []
    }
  },

  methods: {
    showAddressTypes() {
      customerDevelopmentService.getListOfAllAddressTypes().then(response => {
        response.data.forEach(customerGroup => {
          this.addressTypesItems.push(customerGroup)
        })
      })
    },
    showFiveYears() {
      console.warn(this.addressTypesItems[0].description)
      this.headLineText = 'Adressartenentwicklung nach '
      this.fiveYearDataArray = []
      this.entireCustomerInFiveYears = []
      this.activeCustomer = []
      this.inactiveCustomer = []

      customerDevelopmentService
        .getAddressTypesDevelopmentOfFiveYears(
          this.highestYearInFiveShownYears - 4,
          this.selectedAddressType
        )
        .then(response => {
          response.data.forEach(year => {
            this.fiveYearDataArray.push(year.customerNumber)
            this.entireCustomerInFiveYears.push(year.totalCustomer)
            this.activeCustomer.push(year.activeCustomer)
            this.inactiveCustomer.push(year.inactiveCustomer)
          })
        })
    },

    increaseOneYear() {
      this.currentYear--
      this.showMonth(this.currentYear)
    },
    decreaseOneYear() {
      if (this.currentYear < new Date().getFullYear()) {
        this.currentYear++
        this.showMonth(this.currentYear)
      }
    },
    increaseOneYearInFiveYearChart() {
      this.highestYearInFiveShownYears--
      this.showFiveYears()
    },
    decreaseOneYearInFiveYearChart() {
      if (this.highestYearInFiveShownYears < new Date().getFullYear()) {
        this.highestYearInFiveShownYears++
        this.showFiveYears()
      }
    },

    showMonth(year) {
      this.currentYear = year
      this.newCustomerPerMonth = []
      this.entireCustomerData = []
      this.activeCustomerOfMonth = []
      this.inactiveCustomerOfMonth = []

      customerDevelopmentService
        .getAddressTypesDevelopmentOfOneYear(
          this.currentYear,
          this.selectedAddressType
        )
        .then(response => {
          response.data.forEach(month => {
            this.newCustomerPerMonth.push(month.customerNumber)
            this.entireCustomerData.push(month.totalCustomer)
            this.activeCustomerOfMonth.push(month.activeCustomer)
            this.inactiveCustomerOfMonth.push(month.inactiveCustomer)
          })
        })
    }
  },
  beforeMount() {
    this.showAddressTypes()
  }
}
</script>
