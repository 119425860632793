<template>
  <div class="mx-4 my-4">
    <div class="d-flex mx-4">
      <span class="text-h6 font-weight-light d-inline-flex">
        <v-select
          class="me-3 fit"
          hint="Anfangsjahr"
          persistent-hint
          :items="getOfferYearItems"
          v-model="getOfferYearStart"
          @change="selected => yearStartSelectionChange(selected)"
        ></v-select>
        <span class="mt-4 text-center">-</span>
        <v-select
          class="mx-3 fit"
          hint="Endjahr"
          persistent-hint
          :items="getOfferYearItems"
          v-model="getOfferYearEnd"
          @change="selected => yearEndSelectionChange(selected)"
        ></v-select>
      </span>
    </div>

    <div class="d-flex justify-end">
      <v-btn icon color="black" @click="yearBack">
        <v-icon>mdi-arrow-left-thick</v-icon>
      </v-btn>

      <v-btn icon color="black" @click="yearForward">
        <v-icon>mdi-arrow-right-thick</v-icon>
      </v-btn>
    </div>

    <apexchart
      ref="yearChart"
      type="line"
      height="350"
      :options="yearlyChartOptions"
      :series="yearOfferObjectArray"
      @click="showMonth"
    ></apexchart>
    <div v-if="this.selectedYear != -1">
      <v-row class="d-flex justify-end">
        <v-text-field
          class="shrink mb-4 mx-4"
          style="max-width: 250px"
          v-model="search"
          append-icon="mdi-magnify"
          label="Suche"
          single-line
          hide-details
          outlined
        ></v-text-field
      ></v-row>
      <v-row>
        <v-col class="col-md-4">
          <v-data-table
            dense
            show-select
            :single-select="true"
            v-model="selectedOffer"
            :headers="forecastHeaders"
            :items="forecastItems"
            item-key="index"
            :items-per-page="10"
            :loading="loadingList"
            class="elevation-1"
          >
            <template v-slot:item.showOffer="{ item }">
              <OpenLinkEyeComponent
                :destination="{
                  name: 'crmAddressDocumentDetailed',
                  params: { lfdnr: item.lfdnr, wf_cid: item.wf_cid }
                }"
                :newTab="true"
              />
            </template>
          </v-data-table>
        </v-col>
        <v-col>
          <v-data-table
            class="elevation-4 py-2 px-2"
            :headers="articleListHeader"
            :items="articleListArray"
            :items-per-page="10"
            :search="search"
            item-key="nr"
            sort-by="nr"
            no-data-text="Bitte wählen Sie einen Auftrag aus"
          >
            <template v-slot:item.showArticle="{ item }">
              <OpenLinkEyeComponent
                :destination="{
                  name: 'Artikeldetails',
                  params: { articleNumber: item.nr }
                }"
                :newTab="true"
              />
            </template> </v-data-table
        ></v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import OfferService from '@/services/statistics/offerService.js'
import FormatService from '@/services/formatters/currencyFormatService.js'
import MixinMethodObject from '@/components/statistics/offer/IncomingOrders.vue'
import { mapGetters } from 'vuex'
import OpenLinkEyeComponent from '@/components/core/OpenLinkEyeComponent.vue'
const REVENUE_TYPES = ['net', 'db']

export default {
  components: {
    OpenLinkEyeComponent
  },
  mixins: [MixinMethodObject],
  data: () => ({
    selectedOffer: [],
    loadingList: true,
    selectedYear: -1,
    yearOfferObjectArray: [],
    articleListArray: [],
    forecastItems: [],
    articleListHeader: [
      {
        text: 'Name',
        value: 'name',
        align: 'start',
        sortable: true
      },
      {
        text: 'Artikelnummer',
        value: 'nr',
        align: 'start',
        sortable: true
      },
      {
        text: 'Artikel anzeigen',
        value: 'showArticle',
        sortable: false,
        align: 'center',
        width: '12em'
      }
    ],
    search: '',
    forecastHeaders: [
      {},
      {
        text: 'Auftragsnummer',
        value: 'nr',
        align: 'start',
        sortable: true
      },
      {
        text: 'Status',
        value: 'status',
        align: 'start',
        sortable: true
      },
      {
        text: 'Auftrag anzeigen',
        value: 'showOffer',
        sortable: false,
        align: 'center',
        width: '12em'
      }
    ],
    yearlyChartOptions: {
      title: {
        align: 'left',
        style: {
          fontSize: '20px'
        }
      },
      chart: {
        zoom: {
          enabled: false
        }
      },
      markers: {
        enabled: true,
        size: 5
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      stroke: {
        dashArray: [5, 5]
      },
      yaxis: [
        {
          decimalsInFloat: 0,

          lables: {
            formatter: value => FormatService.formatEuro(value)
          }
        }
      ],
      tooltip: {
        shared: true,
        y: {
          formatter: function(value, { series, seriesIndex, dataPointIndex }) {
            if (REVENUE_TYPES[seriesIndex] == 'db') {
              let pvalue = parseFloat(
                (series[seriesIndex][dataPointIndex] /
                  series[seriesIndex - 1][dataPointIndex] || 0) * 100
              )
              return (
                FormatService.formatEuro(value) +
                ' (' +
                (isFinite(pvalue) ? pvalue : 0).toFixed(2) +
                '%)'
              )
            } else if (REVENUE_TYPES[seriesIndex] == 'dbTotal') {
              return (
                FormatService.formatEuro(value) +
                ' (' +
                (
                  (series[seriesIndex][dataPointIndex] /
                    series[seriesIndex - 1][dataPointIndex] || 0) * 100
                ).toFixed(2) +
                '%)'
              )
            } else {
              return FormatService.formatEuro(value)
            }
          }
        }
      },
      xaxis: {
        title: {
          text: 'Jahr'
        }
      }
    }
  }),
  methods: {
    updateOrdersOfYearSpan() {
      let yearYearObjectArray = (this.yearYearObjectArray = [])

      OfferService.getOffersForYearSpan(
        '/forecast',
        this.getOfferYearStart,
        this.getOfferYearEnd
      ).then(res => {
        for (
          let year = this.getOfferYearStart;
          year <= this.getOfferYearEnd;
          year++
        ) {
          yearYearObjectArray.push(year)
        }
        let total = []
        let deckungsbeitrag = []

        res.data.forEach(offerObject => {
          total.push(offerObject.posNetto)
          deckungsbeitrag.push(offerObject.db)
        })

        this.$refs.yearChart.updateSeries([
          {
            name: 'Gesamt',
            data: total
          },
          {
            name: 'Deckungsbeitrag',
            data: deckungsbeitrag
          }
        ])
        this.$refs.yearChart.updateOptions({
          title: {
            text:
              'Jahresansicht ' +
              this.getOfferYearStart +
              ' - ' +
              this.getOfferYearEnd
          },
          xaxis: {
            categories: yearYearObjectArray
          }
        })
      })
    },
    showMonth(event, chartContext, config) {
      this.loadingList = true
      let selected = this.getOfferYearStart + config.dataPointIndex
      this.selectedYear = selected
      this.forecastItems = []
      OfferService.getForecastOfferForYear(selected).then(res => {
        res.data.forEach((element, index) => {
          this.forecastItems.push({
            datum: element.datum.split(' ')[0],
            nr: element.shortType + ' ' + element.nr,
            id: element.nr,
            status: element.state,
            wf_cid: element.wf_cid,
            lfdnr: element.lfdnr,
            index: index
          })
        })

        this.loadingList = false
      })
    },
    showArticleList(selectedElement) {
      this.articleListArray = []
      OfferService.getForecastArticlesForYear([selectedElement[0].id]).then(
        res => {
          res.data.forEach(element => {
            this.articleListArray.push({
              name: element.artName,
              nr: element.artNr
            })
          })
        }
      )
    }
  },
  computed: {
    ...mapGetters([
      'getOfferYearStart',
      'getOfferYearEnd',
      'getOfferYearItems'
    ]),
    getYearStartAndGetYearEnd() {
      return `${this.getOfferYearStart}|${this.getOfferYearEnd}`
    }
  },
  watch: {
    getYearStartAndGetYearEnd() {
      this.updateOrdersOfYearSpan()
    },
    selectedOffer() {
      this.showArticleList(this.selectedOffer)
    }
  }
}
</script>

<style scoped>
.v-select__selections {
  font-weight: bold;
  padding-right: 50px;
}
.v-select.fit {
  width: 100px;
}
.v-select.fit .v-select__selection--comma {
  text-overflow: unset;
}
</style>
