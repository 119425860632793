<template>
  <v-container
    ><SiteHeaderComponent :header="address.name" />
    <div class="text-center grey--text text--darken-2 mb-12">
      <h3>
        <router-link
          :to="{
            name: 'statisticsDistributorArticleTypes',
            params: { lfdnr: lfdnr }
          }"
          >{{ result.typeName }}
        </router-link>
        > Artikelkategorien
      </h3>
    </div>
    <div class="d-flex justify-end">
      <v-btn
        class="rounded-pill"
        color="primary"
        :to="{
          name: 'statisticsArticleSearch',
          params: { lfdnr: lfdnr },
          query: this.$route.query
        }"
      >
        <v-icon class="mr-2"> mdi-magnify </v-icon>
        Artikelsuche
      </v-btn>
    </div>
    <div>
      <TimeFilter />
    </div>

    <v-card class="mx-auto mb-12">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suche"
          outlined
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="articleRevenue"
        :search="search"
        @click:row="openArticleType"
      >
        <template v-slot:item.finalValue="{ item }">
          <b class="secondary pa-1 px-4 rounded-pill white--text">
            {{ item.finalValue }}
          </b>
        </template>

        <template v-slot:no-data>
          Keine Artikelkategorien in diesem Zeitraum vorhanden.
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

// Services
import addressService from '@/services/crm/addressService.js'
import RevenueService from '@/services/statistics/revenues.js'

// Components
import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue'
import TimeFilter from '@/components/statistics/TimeFilter.vue'

export default {
  components: {
    SiteHeaderComponent,
    TimeFilter
  },
  data: () => ({
    address: {
      name: '',
      street: '',
      postcode: '',
      city: '',
      country: '',
      tel: '',
      email: '',
      addressTypes: []
    },
    result: {},
    articleRevenue: [],
    loading: true,
    search: '',
    sortDesc: false,
    editorContent: '',
    headers: [
      {
        text: 'Kategorie',
        value: 'description'
      },
      { text: 'Umsatz', value: 'revenue', align: 'end' }
    ]
  }),
  props: {
    lfdnr: {
      type: Number,
      required: true
    },
    articleType: {
      type: String,
      required: true
    }
  },
  methods: {
    init() {
      addressService.getAddress(this.lfdnr).then(response => {
        this.address = response.data
      })

      this.loadArticleCategoriesStatistics()
    },
    loadArticleCategoriesStatistics() {
      this.loading = true
      RevenueService.getCustomerArticleTypes2(
        this.lfdnr,
        this.articleType,
        this.filterFromDate,
        this.filterToDate
      ).then(response => {
        this.articleRevenue = response.data
        this.loading = false
      })
    },
    openArticleCategory(item) {
      // statisticsDistributorArticleCategories
      this.$router.push({
        name: 'statisticsDistributorArticles',
        query: {
          from: this.filterFromDate,
          to: this.filterToDate
        },
        params: {
          lfdnr: this.lfdnr,
          articleType: this.articleType,
          category: item.nr
        }
      })
    }
  },
  computed: {
    ...mapState({
      filterFromDate: state => state.statisticsTimeFilter.fromDate,
      filterToDate: state => state.statisticsTimeFilter.toDate
    })
  },
  mounted() {
    this.init()
  },
  watch: {
    filterFromDate: function() {
      this.loadArticleCategoriesStatistics()
    },
    filterToDate: function() {
      this.loadArticleCategoriesStatistics()
    }
  }
}
</script>

<style scoped>
.datatable-row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
