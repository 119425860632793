<template>
  <v-container class="disabled">
    <p class="d-flex justify-center">
      <v-card
        elevation="2"
        class="mt-10 mb-10 pa-5 text-center red--text text--lighten-1"
        style="width: 50vw"
      >
        Fibukonten sind noch nicht vollständig in der Datenbank implementiert
      </v-card>
    </p>
    <div class="d-none">
      <!---------------------Ab hier unsichtbar----------------------->
      <v-card-title>
        Fibukonto auswählen
        <v-spacer></v-spacer>
        <v-text-field
          v-model="fibuKontenSearch"
          append-icon="mdi-magnify"
          label="Suche"
          single-line
          outlined
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-model="fibuKontenSelected"
        :headers="fibuKontenHeaders"
        :items="fibuKontenArray"
        :single-select="singleSelect"
        :search="fibuKontenSearch"
        :items-per-page="10"
        item-key="group"
        show-select
        class="elevation-1"
        @input="showArticlesInFibuKonten"
      >
      </v-data-table>
      <template v-if="this.fibuKontenSelected.length != 0">
        <v-card-title>
          <v-text-field
            v-model="articlesInfibuKontenSearch"
            append-icon="mdi-magnify"
            label="Suche"
            single-line
            outlined
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="articlesInfibuKontenHeaders"
          :items="articlesInfibuKontenArray"
          :search="articlesInfibuKontenSearch"
          :items-per-page="10"
          item-key="articlename"
          class="elevation-1"
        >
        </v-data-table>
      </template>
      <!------------------------------------------------------>
      <div v-if="this.fibuKontenSelected.length != 0">
        <div class="header">
          <h2>
            Entwicklung der Fibukonten
            <span class="subtitle-1"
              >{{ this.highestYearInFiveShownYearsOfFibuKonten - 4 }}-{{
                this.highestYearInFiveShownYearsOfFibuKonten
              }}</span
            >
          </h2>
          <v-col class="d-flex justify-end">
            <v-btn icon color="black" @click="increaseFiveYears">
              <v-icon>mdi-arrow-left-thick</v-icon>
            </v-btn>

            <v-btn icon color="black" @click="decreaseFiveYears">
              <v-icon>mdi-arrow-right-thick</v-icon>
            </v-btn>
          </v-col>
        </div>
        <apexchart
          ref="fiveYearChartsOfFibuKonten"
          type="line"
          height="350"
          :options="fiveYearChartOptionsOfFibuKonten"
          :series="fiveYearChartsOfFibuKontenSeries"
        ></apexchart>
      </div>
      <!------------------------------------------------------>
      <div v-if="selectedYear != 0">
        <div class="header">
          <h2>
            Monatsansicht
            <span class="subtitle-1">{{ this.selectedYear }}</span>
          </h2>
          <v-col class="d-flex justify-end">
            <v-btn icon color="black" @click="increaseOneYear">
              <v-icon>mdi-arrow-left-thick</v-icon>
            </v-btn>

            <v-btn icon color="black" @click="decreaseOneYear">
              <v-icon>mdi-arrow-right-thick</v-icon>
            </v-btn>
          </v-col>
        </div>
        <apexchart
          ref="oneYearChartsOfFibuKonten"
          type="line"
          height="350"
          :options="oneYearChartOptionsOfFibuKonten"
          :series="oneYearChartsOfFibuKontenSeries"
        ></apexchart>
      </div>
      <!------------------Bis hier unsichtbar-------------------------->
    </div>
  </v-container>
</template>

<script>
import fibuKontenService from '@/services/statistics/fibukontenService.js'
import { formatStringForTooltip } from '@/services/statistics/formatter.js'
import { monthNames } from '@/services/statistics/chartService.js'

export default {
  data: function() {
    return {
      fibuKontenSearch: '',
      articlesInfibuKontenSearch: '',
      fibuKontenSelected: [],
      fibuObjectArray: [],
      fibuKontenArray: [],
      singleSelect: true,
      highestYearInFiveShownYearsOfFibuKonten: new Date().getFullYear(),
      selectedYear: 0,
      fibuKontenHeaders: [
        {
          text: 'Fibukonto',
          value: 'group',
          align: 'start',
          sortable: true
        }
      ],
      articlesInfibuKontenHeaders: [
        {
          text: 'Artikel',
          value: 'articlename',
          align: 'start',
          sortable: true
        },
        {
          text: 'Artikelnummer',
          value: 'artNr',
          align: 'start',
          sortable: true
        }
      ],
      articlesInfibuKontenArray: [],
      articlesInFibuKontenObjectArray: [],
      ///////////////////////////
      // Five Year Chart //
      fiveYearChartsOfFibuKontenSeries: [],
      fiveYearChartOptionsOfFibuKonten: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: (event, chartContext, config) => {
              this.showMonth(
                this.highestYearInFiveShownYearsOfFibuKonten -
                  4 +
                  config.dataPointIndex
              )
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: [
            new Date().getFullYear() - 4,
            new Date().getFullYear() - 3,
            new Date().getFullYear() - 2,
            new Date().getFullYear() - 1,
            new Date().getFullYear()
          ]
        },
        yaxis: [
          {
            /*
            data from api: 4349.4685
            data after format: 4.000 €
          */
            decimalsInFloat: 0,
            labels: {
              formatter: function(value) {
                return (
                  ('' + value).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, function(
                    $1
                  ) {
                    return $1 + '.'
                  }) + ' €'
                )
              }
            }
          }
        ],
        tooltip: {
          shared: true,
          y: {
            formatter: value => formatStringForTooltip(value)
          }
        }
      },
      ////////////////////
      // One Year Chart //
      oneYearChartsOfFibuKontenSeries: [],
      oneYearChartOptionsOfFibuKonten: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: monthNames
        },
        yaxis: [
          {
            /*
            data from api: 4349.4685
            data after format: 4.000 €
          */
            decimalsInFloat: 0,
            labels: {
              formatter: function(value) {
                return (
                  ('' + value).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, function(
                    $1
                  ) {
                    return $1 + '.'
                  }) + ' €'
                )
              }
            }
          }
        ],
        tooltip: {
          shared: true,
          y: {
            formatter: value => formatStringForTooltip(value)
          }
        }
      }
      //////////////////////////
    }
  },
  methods: {
    shwoStatistikGroups() {
      fibuKontenService.getListOfAllFibuKonten().then(response => {
        this.fibuObjectArray = response.data

        for (let i = 0; i < this.fibuObjectArray.length; i++) {
          this.fibuKontenArray.push({
            group: this.fibuObjectArray[i].bezeichnung,
            code: this.fibuObjectArray[i].code
          })
        }
      })
    },
    showArticlesInFibuKonten() {
      this.selectedYear = 0

      console.log(this.fibuKontenSelected[0].code)
      fibuKontenService
        .getListOfArticlesInFibuKonten(this.fibuKontenSelected[0].code)
        .then(response => {
          this.articlesInFibuKontenObjectArray = response.data
          this.articlesInfibuKontenArray = []
          for (
            let i = 0;
            i < this.articlesInFibuKontenObjectArray.length;
            i++
          ) {
            this.articlesInfibuKontenArray.push({
              articlename: this.articlesInFibuKontenObjectArray[i]
                .artBezeichung,
              artNr: this.articlesInFibuKontenObjectArray[i].artNr
            })
          }
        })
      this.showFiveYearsOfArticleGroups()
    },
    showFiveYearsOfArticleGroups() {
      let dbSum = []
      let posNettoSum = []
      let dbYear = []
      let revenueYear = []
      let responseDataArray = []
      console.log(
        this.fibuKontenSelected[0].code,
        this.highestYearInFiveShownYearsOfFibuKonten - 4
      )
      fibuKontenService
        .getFiveYearDataOfFibuKonten(
          this.fibuKontenSelected[0].code,
          this.highestYearInFiveShownYearsOfFibuKonten - 4
        )
        .then(response => {
          responseDataArray = response.data

          for (let i = 0; i < responseDataArray.length; i++) {
            dbSum.push(responseDataArray[i].dbSum)
            posNettoSum.push(responseDataArray[i].posNettoSum)

            revenueYear.push(responseDataArray[i].revenueYear)
            dbYear.push(responseDataArray[i].dbYear)
          }
          this.$refs.fiveYearChartsOfFibuKonten.updateOptions({
            xaxis: {
              categories: [
                this.highestYearInFiveShownYearsOfFibuKonten - 4,
                this.highestYearInFiveShownYearsOfFibuKonten - 3,
                this.highestYearInFiveShownYearsOfFibuKonten - 2,
                this.highestYearInFiveShownYearsOfFibuKonten - 1,
                this.highestYearInFiveShownYearsOfFibuKonten
              ]
            }
          })
          this.$refs.fiveYearChartsOfFibuKonten.updateSeries([
            {
              name: 'Deckungsbeitrag',
              data: dbYear,
              type: 'line'
            },
            {
              name: 'Umsatz',
              data: revenueYear,
              type: 'line'
            },
            {
              name: 'Deckungsbeitrag gesamt',
              data: dbSum,
              type: 'bar'
            },
            {
              name: 'Umsatz gesamt',
              data: posNettoSum,
              type: 'bar'
            }
          ])
        })
    },
    showMonth(year) {
      console.log(year)
      this.selectedYear = year

      let dbSum = []
      let posNettoSum = []
      let dbYear = []
      let revenueMonth = []
      let responseDataArray = []

      fibuKontenService
        .getOneYearDataOfFibuKonten(this.fibuKontenSelected[0].code, year)
        .then(response => {
          responseDataArray = response.data

          for (let i = 0; i < responseDataArray.length; i++) {
            dbSum.push(responseDataArray[i].dbSum)
            posNettoSum.push(responseDataArray[i].posNettoSum)

            revenueMonth.push(responseDataArray[i].revenueMonth)
            dbYear.push(responseDataArray[i].dbMonth)
          }

          this.$refs.oneYearChartsOfFibuKonten.updateSeries([
            {
              name: 'Deckungsbeitrag',
              data: dbYear,
              type: 'line'
            },
            {
              name: 'Umsatz',
              data: revenueMonth,
              type: 'line'
            },
            {
              name: 'Deckungsbeitrag gesamt',
              data: dbSum,
              type: 'bar'
            },
            {
              name: 'Umsatz gesamt',
              data: posNettoSum,
              type: 'bar'
            }
          ])
        })
    },
    increaseOneYear() {
      this.selectedYear--
      this.showMonth(this.selectedYear)
    },
    decreaseOneYear() {
      if (this.selectedYear < new Date().getFullYear()) {
        this.selectedYear++
        this.showMonth(this.selectedYear)
      }
    },
    increaseFiveYears() {
      this.highestYearInFiveShownYearsOfFibuKonten--
      this.showFiveYearsOfArticleGroups()
    },
    decreaseFiveYears() {
      if (
        this.highestYearInFiveShownYearsOfFibuKonten < new Date().getFullYear()
      ) {
        this.highestYearInFiveShownYearsOfFibuKonten++
        this.showFiveYearsOfArticleGroups()
      }
    }
  },
  beforeMount() {
    this.shwoStatistikGroups()
  }
}
</script>

<style></style>
